<template>
  <div class="MenuList">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button v-if="isCompany" text="恢复菜单" permission="recovermenu" @click="recover" />
        <v-button v-if="isPlatform" text="新增" permission="add" @click="create" />
      </template>
      <template v-if="isPlatform" #tableLeftSlot>
        <div class="tree-group">
          <div class="tree-group-header">
            <div>菜单层级</div>
            <v-button text="编辑" type="text" permission="update" @click="updateTree" />
          </div>
          <div class="tree-group-content">
            <v-tree
              :data="treeData"
              :defaultProps="treeProps"
              :expand-on-click-node="false"
              @nodeClick="selectNode"
            />
          </div>
        </div>
      </template>
      <template #searchSlot>
        <v-input label="菜单名称" v-model="searchParams.menuName" />
        <v-select label="使用终端" v-model="searchParams.menuScope" :options="menuScopeOps" />
        <v-select label="层级" v-model="searchParams.menuLevel" :options="menuLevelOps" />
        <v-select label="类型" v-model="searchParams.menuType" :options="menuTypeOps" />
        <v-select label="打开方式" v-model="searchParams.urlJumpType" :options="urlJumpTypeOps" />
        <v-select label="授权方式" v-model="searchParams.authType" :options="authTypeOps" />
        <v-select2 ref="parentMenu" label="上级菜单" v-model="searchParams.parentId" v-bind="parentMenuParams" />
        <v-select label="状态" v-model="searchParams.visible" :options="visibleOps" />
        <v-select label="角色归属" v-model="searchParams.allowUserType" :options="allowUserTypeOps" />
        <v-input label="特殊编码" v-model="searchParams.specialNo" />
        <v-input label="版本号" v-model="searchParams.createVersion" />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="isPlatform && scope.row.showOperation === 1 && !(isCompany&&scope.row.menuType==3)" text="编辑" type="text" permission="update" @click="update(scope.row)" />
        <v-button v-if="scope.row.menuType !== 3 && scope.row.visible === 1" text="添加子菜单" type="text" permission="add" @click="createChild(scope.row)" />
        <v-button v-if="isPlatform && scope.row.showOperation === 1 && ![-1, 0].includes(scope.row.menuLevel)&&!isCompany" :text="getVisibleText(scope.row)" type="text" permission="switch" @click="updateVisible(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { vTree } from 'components/control'
import { createImgVNode } from 'common/vdom'
import { queryListURL, exportListURL, queryMenuListURL, getUpdateVisibleURL, getMenuResourceURL, recoverURL } from './api'
import {
  menuLevelMap,
  setMenuLevelOps,
  menuTypeMap,
  setMenuTypeOps,
  urlJumpTypeMap,
  setUrlJumpTypeOps,
  authTypeMap,
  setAuthTypeOps,
  visibleMap,
  setVisibleOps,
  setAllowUserTypeOps,
  menuScopeMap,
  menuScopeOps
} from './map'

export default {
  name: 'MenuList',
  components: {
    vTree
  },
  data () {
    return {
      searchUrl: queryListURL,
      exportUrl: exportListURL,
      menuLevelOps: setMenuLevelOps(1),
      menuTypeOps: setMenuTypeOps(1),
      urlJumpTypeOps: setUrlJumpTypeOps(1),
      authTypeOps: setAuthTypeOps(1),
      visibleOps: setVisibleOps(1),
      allowUserTypeOps: setAllowUserTypeOps(1),
      menuScopeOps: menuScopeOps(1),
      menuScopeMap,
      parentMenuParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      searchParams: {
        menuName: '',
        menuLevel: undefined,
        menuType: undefined,
        urlJumpType: undefined,
        authType: undefined,
        parentId: undefined,
        visible: undefined,
        allowUserType: undefined,
        specialNo: '',
        createVersion: '',
        menuScope: undefined
      },
      headers: [
        {
          prop: 'iconUrl',
          label: '图标',
          formatter: (row, prop) => {
            if (!row[prop]) {
              return ''
            }
            return createImgVNode(this, row, prop)
          }
        },
        {
          prop: 'menuName',
          label: '菜单名称'
        },
        {
          prop: 'menuLevelText',
          label: '层级',
          formatter (row) {
            return menuLevelMap[row.menuLevel]
          }
        },
        {
          prop: 'menuScopeText',
          label: '使用终端',
          formatter (row) {
            return menuScopeMap[row.menuScope]
          }
        },
        {
          prop: 'menuTypeText',
          label: '类型',
          formatter (row) {
            return menuTypeMap[row.menuType]
          }
        },
        {
          prop: 'urlJumpTypeText',
          label: '打开方式',
          formatter (row) {
            return row.urlJumpType === -1 ? '/' : urlJumpTypeMap[row.urlJumpType]
          }
        },
        {
          prop: 'authTypeText',
          label: '授权方式',
          formatter (row) {
            return row.urlJumpType === -1 ? '/' : authTypeMap[row.authType]
          }
        },
        {
          prop: 'parentName',
          label: '上级菜单'
        },
        {
          prop: 'allowUserTypeNameText',
          label: '角色归属',
          formatter (row) {
            return row.allowUserTypeName === '' ? '/' : row.allowUserTypeName
          }
        },
        {
          prop: 'specialNo',
          label: '特殊编码'
        },
        {
          prop: 'createVersion',
          label: '版本号'
        },
        {
          prop: 'visibleText',
          label: '状态',
          formatter (row) {
            return visibleMap[row.visible]
          }
        }
      ],
      treeData: [],
      treeProps: {
        children: 'childMenus',
        label: 'name'
      }
    }
  },
  created () {
    if (this.isPlatform) {
      this.getMenuResource()
    }
  },
  computed: {
    isPlatform () {
      return this.$store.state.userInfo.userType === '106'|| this.$store.state.userInfo.userType === '102'
    },
    isCompany () {
      return this.$store.state.userInfo.userType === '102'
    }
  },
  methods: {
    async getMenuResource () {
      const { status, data } = await this.$axios.get(getMenuResourceURL)
      if (status === 100) {
        this.treeData = data
      }
    },
    selectNode (data) {
      let { name, level, menuType, pId } = data
      this.$nextTick(() => {
        this.$set(this.searchParams, 'menuName', name)
        this.$set(this.searchParams, 'menuLevel', level)
        this.$set(this.searchParams, 'menuType', menuType)
        if (pId !== 0) {
          this.$set(this.searchParams, 'parentId', pId)
        } else {
          this.$set(this.searchParams, 'parentId', undefined)
          this.$refs.parentMenu.clearValue()
        }
        this.$refs.list.searchPanelSearchData()
      })
    },
    getVisibleText (row) {
      let { visible } = row
      let visibleText = ''
      if (visible === 0) {
        visibleText = '开启'
      } else if (visible === 1) {
        visibleText = '关闭'
      }
      return visibleText
    },
    async updateVisible (row) {
      let { id, visible } = row
      let url = ''
      if (visible === 0) {
        url = getUpdateVisibleURL(id, 1)
      } else if (visible === 1) {
        let message = ''
        if (row.menuType === 3) {
          message = '是否关闭该菜单'
        } else {
          message = '是否关闭该菜单及其下所有子菜单'
        }
        let result = await this.$confirm(message)
        if (!result) {
          return
        }
        url = getUpdateVisibleURL(id, 0)
      }

      // 拼接操作日志的操作对象参数 规则：菜单名称-上级菜单名称
      const { status } = await this.$axios.put(url, {
        dataObject: `${row.menuName}-${row.parentName}`
      })
      if (status === 100) {
        this.$refs.list.searchData()
        this.$message.success('操作成功')
      }
    },
    create () {
      this.$router.push({
        name: 'menuForm'
      })
    },
    createChild (row) {
      this.$router.push({
        name: 'menuForm',
        query: {
          parentId: row.id
        }
      })
    },
    update (row) {
      this.$router.push({
        name: 'menuForm',
        query: {
          id: row.id
        }
      })
    },
    updateTree () {
      this.$router.push({
        name: 'menuFormTree'
      })
    },
    async recover () {
      let isConfirm = await this.$confirm('确定恢复菜单吗？')
      if (isConfirm) {
        this.$axios.post(recoverURL).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
            this.$message.success('操作成功')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MenuList {
  .tree-group {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 100%;
    text-align: left;
    .tree-group-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 47px;
      color: rgb(144, 147, 153);
      padding: 0 10px;
      border-top: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
      border-left: 1px solid #EBEEF5;
    }
    .tree-group-content {
      flex: 1;
      overflow: auto;
      padding: 8px;
      border-bottom: 1px solid #EBEEF5;
      border-left: 1px solid #EBEEF5;
      ::v-deep .custom-tree-node {
        .icon {
          display: none;
        }
      }
    }
  }
}
</style>
