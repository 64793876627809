// 获取列表
const queryListURL = `${API_CONFIG.controlBaseURL}menu/list`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}menu/export`
// 获取菜单列表 上级菜单select2
const queryMenuListURL = `${API_CONFIG.controlBaseURL}menu/select2`
// 开启/关闭
const getUpdateVisibleURL = (id, visible) => {
  return `${API_CONFIG.controlBaseURL}menu/${id}/switch/${visible}`
}
// 获取菜单列表
const getMenuResourceURL = `${API_CONFIG.controlBaseURL}menu/menuResource`

// 获取可用id
const queryNewMenuIdURL = `${API_CONFIG.controlBaseURL}menu/getNewMenuId`
// 新增
const createURL = `${API_CONFIG.controlBaseURL}menu/add`
// 编辑
const editURL = `${API_CONFIG.controlBaseURL}menu/update`
// 查询详情
const getQueryDetailURL = (id) => {
  return `${API_CONFIG.controlBaseURL}menu/${id}/details`
}
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// 移动菜单
const menuMoveURL = `${API_CONFIG.controlBaseURL}menu/menuMove`
// 恢复菜单
const recoverURL = `${API_CONFIG.controlBaseURL}menu/recover`
export {
  queryListURL,
  exportListURL,
  queryMenuListURL,
  getUpdateVisibleURL,
  getMenuResourceURL,
  queryNewMenuIdURL,
  createURL,
  editURL,
  getQueryDetailURL,
  uploadURL,
  menuMoveURL,
  recoverURL
}
