var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MenuList" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "headerSlot",
              fn: function () {
                return [
                  _vm.isCompany
                    ? _c("v-button", {
                        attrs: { text: "恢复菜单", permission: "recovermenu" },
                        on: { click: _vm.recover },
                      })
                    : _vm._e(),
                  _vm.isPlatform
                    ? _c("v-button", {
                        attrs: { text: "新增", permission: "add" },
                        on: { click: _vm.create },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            _vm.isPlatform
              ? {
                  key: "tableLeftSlot",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "tree-group" }, [
                        _c(
                          "div",
                          { staticClass: "tree-group-header" },
                          [
                            _c("div", [_vm._v("菜单层级")]),
                            _c("v-button", {
                              attrs: {
                                text: "编辑",
                                type: "text",
                                permission: "update",
                              },
                              on: { click: _vm.updateTree },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tree-group-content" },
                          [
                            _c("v-tree", {
                              attrs: {
                                data: _vm.treeData,
                                defaultProps: _vm.treeProps,
                                "expand-on-click-node": false,
                              },
                              on: { nodeClick: _vm.selectNode },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "searchSlot",
              fn: function () {
                return [
                  _c("v-input", {
                    attrs: { label: "菜单名称" },
                    model: {
                      value: _vm.searchParams.menuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "menuName", $$v)
                      },
                      expression: "searchParams.menuName",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "使用终端", options: _vm.menuScopeOps },
                    model: {
                      value: _vm.searchParams.menuScope,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "menuScope", $$v)
                      },
                      expression: "searchParams.menuScope",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "层级", options: _vm.menuLevelOps },
                    model: {
                      value: _vm.searchParams.menuLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "menuLevel", $$v)
                      },
                      expression: "searchParams.menuLevel",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "类型", options: _vm.menuTypeOps },
                    model: {
                      value: _vm.searchParams.menuType,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "menuType", $$v)
                      },
                      expression: "searchParams.menuType",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "打开方式", options: _vm.urlJumpTypeOps },
                    model: {
                      value: _vm.searchParams.urlJumpType,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "urlJumpType", $$v)
                      },
                      expression: "searchParams.urlJumpType",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "授权方式", options: _vm.authTypeOps },
                    model: {
                      value: _vm.searchParams.authType,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "authType", $$v)
                      },
                      expression: "searchParams.authType",
                    },
                  }),
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "parentMenu",
                        attrs: { label: "上级菜单" },
                        model: {
                          value: _vm.searchParams.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "parentId", $$v)
                          },
                          expression: "searchParams.parentId",
                        },
                      },
                      "v-select2",
                      _vm.parentMenuParams,
                      false
                    )
                  ),
                  _c("v-select", {
                    attrs: { label: "状态", options: _vm.visibleOps },
                    model: {
                      value: _vm.searchParams.visible,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "visible", $$v)
                      },
                      expression: "searchParams.visible",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "角色归属", options: _vm.allowUserTypeOps },
                    model: {
                      value: _vm.searchParams.allowUserType,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "allowUserType", $$v)
                      },
                      expression: "searchParams.allowUserType",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "特殊编码" },
                    model: {
                      value: _vm.searchParams.specialNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "specialNo", $$v)
                      },
                      expression: "searchParams.specialNo",
                    },
                  }),
                  _c("v-input", {
                    attrs: { label: "版本号" },
                    model: {
                      value: _vm.searchParams.createVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "createVersion", $$v)
                      },
                      expression: "searchParams.createVersion",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "operateSlot",
              fn: function (scope) {
                return [
                  _vm.isPlatform &&
                  scope.row.showOperation === 1 &&
                  !(_vm.isCompany && scope.row.menuType == 3)
                    ? _c("v-button", {
                        attrs: {
                          text: "编辑",
                          type: "text",
                          permission: "update",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.update(scope.row)
                          },
                        },
                      })
                    : _vm._e(),
                  scope.row.menuType !== 3 && scope.row.visible === 1
                    ? _c("v-button", {
                        attrs: {
                          text: "添加子菜单",
                          type: "text",
                          permission: "add",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.createChild(scope.row)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.isPlatform &&
                  scope.row.showOperation === 1 &&
                  ![-1, 0].includes(scope.row.menuLevel) &&
                  !_vm.isCompany
                    ? _c("v-button", {
                        attrs: {
                          text: _vm.getVisibleText(scope.row),
                          type: "text",
                          permission: "switch",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateVisible(scope.row)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }